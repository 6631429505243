:root {
  --clr-primary: hsl(180, 53%, 35%);
  --clr-secondary: hsl(0, 0%, 32%);
  --clr-secondary-100: hsl(0, 0%, 90%);
}
body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: Ubuntu, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}
img {
  width: 100%;
  object-fit: contain;
}
*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: var(--clr-secondary-100);
}
*::-webkit-scrollbar-thumb {
  background: var(--clr-primary);
}

.Mui-disabled {
  cursor: not-allowed !important;
}

main {
  min-height: 100vh;
}
@media print {
  body *:is(.not-printable, .not-printable) {
    display: none;
  }
}
