.cv-template {
  overflow-x: scroll;
}
.cv-common * {
  box-sizing: border-box;
}
.cv-common .cover img {
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
}
.cv-common section legend {
  word-spacing: 8px;
}
.cv-common section .information {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.cv-common section .information .item {
  max-width: 800px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  place-items: center start;
}
.cv-common section .information p {
  margin: 0;
}
.cv-common section .information p[data-type="title"] {
  font-size: 1.5rem;
}

.cv-common .slider {
  height: 1rem;
  position: relative;
}
.cv-common .slider::after,
.cv-common .slider::before {
  position: absolute;
  content: "";
  height: 100%;
  border: 1px solid var(--color);
}
.cv-common .slider::before {
  width: 100%;
}
.cv-common .slider::after {
  width: min(calc(1% * var(--i, 10)), 100%);
  background: var(--color);
}

.cv-common h1 {
  font-size: 3rem;
  margin: 0;
  padding: 0;
  line-height: 1;
}
.cv-common p {
  margin: 0;
}
